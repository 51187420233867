export default function dropdown() {
  const dropdown = '[data-dropdown="dropdown"]';
  const open = $('[data-dropdown="open"]');
  const menu = '[data-dropdown="menu"]';
  const active = 'active';
  const subDropdown = '[data-dropdown="subdropdown"]';
  const subOpen = $('[data-dropdown="subopen"]');
  const subMenu = '[data-dropdown="submenu"]';

  open.click(function() {
    if ($(this).closest(dropdown).find(menu).is(':hidden')) {
      $(subDropdown).find(subMenu).hide();
      $(dropdown).removeClass(active);
      $(dropdown).find(menu).slideUp();
      $(this).closest(dropdown).addClass(active);
      $(this).closest(dropdown).find(menu).slideDown();
    } else {
      $(this).closest(dropdown).removeClass(active);
      $(this).closest(dropdown).find(menu).slideUp();
    }
  })
  
  subOpen.click(function() {
    if ($(this).closest(subDropdown).find(subMenu).is(':hidden')) {
      $(subDropdown).find(subMenu).slideUp();
      $(this).closest(subDropdown).find(subMenu).slideDown();
    } else {
      $(this).closest(subDropdown).find(subMenu).slideUp();
    }
  })

}