import validate from 'jquery-validation';
import mask from 'jquery-mask-plugin';

export default function sendForm(){
	$('[name="phone"]').mask('(00) 00000-0000');
	$('[name="date"]').mask('00/00/0000');
	// $('[name="rg"]').mask('00.000.000-0');
	$('[name="cpf"]').mask('000.000.000-00', {reverse: true});
	const selectform = $('[data-formvalidate]');
	if (selectform.length) {
		selectform.each(function(index, el) {
			const message = $(el).data('message');
			$(this).validate({
				errorPlacement: function(error, element) {
					if (message) {
						element.after(error);
					}
					return false;
				},
				messages: {
					name: {
						required: "Campo nome é obrigatório"
					},
					email: {
						required : "Campo e-mail é obrigatório",
						email : "Por favor insira um e-mail válido"
					},
					message: {
						required : "Campo descrição é obrigatório"
					}
				},
				submitHandler: function(form){
					var data;
					var contentType = "application/x-www-form-urlencoded";
					var processData = true;
					if ($(form).attr('enctype') == 'multipart/form-data') {
						data = new FormData($(form).get(0));
						contentType = false;
						processData = false;
					} else {
						data = $(form).serialize();
					}
					$.ajax({
						data       : data,
						type       : $(form).attr('method'),
						url        : $(form).attr('action'),
						contentType: contentType,
						processData: processData,
						success:function(data) {
							data = JSON.parse(data);
							Swal.fire({
								type: data.status,
								title: data.status === "success" ? "Muito Obrigado!" : "Ops!",
								text: data.message
							});
							if(data.status === "success"){
								form.reset();
							}
						}
					});
				}
			});
		});
	}
}