// FUNÇÕES/MÓDULOS SITE
// import menuFixed from './modules/menuFixed';
import sendForm from './modules/sendForm';
import dropdown from './modules/dropdown';
import menuToggle from './modules/menuToggle';
import checkCurriculum from './modules/checkCurriculum';

checkCurriculum();
// menuFixed();
sendForm();
dropdown();
menuToggle();