export default function checkCurriculum() {
  const input =  $('[data-curriculum="input"]');
  const block =  $('[data-curriculum="block"]');
  input.change(() => {
    if (input.val() != '') {
      block.addClass('active');
    } else {
      block.removeClass('active');
    }
  })
}